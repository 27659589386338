@use "mixins/border-radius" as *;
@use "variables" as *;

.callout {
  // scss-docs-start callout-css-vars
  --#{$prefix}callout-padding-x: #{$callout-padding-x};
  --#{$prefix}callout-padding-y: #{$callout-padding-y};
  --#{$prefix}callout-margin-x: #{$callout-margin-x};
  --#{$prefix}callout-margin-y: #{$callout-margin-y};
  --#{$prefix}callout-border-width: #{$callout-border-width};
  --#{$prefix}callout-border-color: #{$callout-border-color};
  --#{$prefix}callout-border-left-width: #{$callout-border-left-width};
  --#{$prefix}callout-border-radius: #{$callout-border-radius};
  // scss-docs-end callout-css-vars

  padding: var(--#{$prefix}callout-padding-y) var(--#{$prefix}callout-padding-x);
  margin: var(--#{$prefix}callout-margin-y) var(--#{$prefix}callout-margin-x);
  border: var(--#{$prefix}callout-border-width) solid var(--#{$prefix}callout-border-color);
  border-inline-start-color: var(--#{$prefix}callout-border-left-color);
  border-inline-start-width: var(--#{$prefix}callout-border-left-width);
  @include border-radius(var(--#{$prefix}callout-border-radius));
}

// scss-docs-start callout-modifiers
// Generate contextual modifier classes for colorizing the collor.
@each $state, $value in $callout-variants {
  .callout-#{$state} {
    --#{$prefix}callout-border-left-color: #{$value};
  }
}
// scss-docs-end callout-modifiers
