@use "../mixins/visually-hidden" as *;

//
// Visually hidden
//

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  @include visually-hidden();
}
